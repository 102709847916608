import React from 'react';
import { ImLeaf } from 'react-icons/im';
import { GiBirchTrees, GiKiwiFruit, GiFruitTree } from 'react-icons/gi';
import { BsTreeFill } from 'react-icons/bs';
import introImg from '../../images/intro-img.jpeg';
import cocopeat from '../../images/cocopeat.jpeg';
import EcoliteCocopeat from './EcoliteCocopeat';
import ApplicationLi from '../peat-moss/ApplicationLi';

const Cocopeat = () => {
  return (
    <div
      className="vermiculite-intro-wrapper pb-3"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div
        style={{ height: '200px', background: `url(${introImg}) no-repeat` }}
        className="vermiculite-intro"
      ></div>
      <div className="px-4 py-5 md:px-6 lg:px-8">
        <div
          className="flex flex-column lg:flex-row lg:align-items-center lg:justify-content-between relative"
          style={{ marginTop: '-2rem', top: '-70px', marginBottom: '-70px' }}
        >
          <div>
            <div
              style={{ width: '140px', height: '140px', borderRadius: '10px' }}
              className="mb-3 flex align-items-center justify-content-center"
            >
              <img
                src={cocopeat}
                alt="cocopeat"
                className="border-round"
                style={{ width: '100%', height: '100%' }}
              />
            </div>

            <div className="mb-3">
              <div className="border-bottom-1 surface-border">
                <span className="block text-3xl font-medium text-900 mb-4">
                  Cocopeat
                </span>
              </div>
            </div>

            <p className="mt-0 mb-3 text-700 text-xl">
              What is <span className="green-c">Cocopeat</span>?
            </p>
            <div className="text-600 font-medium">
              <p className="mb-3">
                Cocopeat is natural fiber made out of coconut husks. The
                extraction of coconut fiber from husks gives us this byproduct
                called coco-peat, which is a 100% natural growing medium. Coco
                Peat is used as pure substrate for different crops or in blended
                mixtures. It retains water well that is highly available for
                crops. Coco Peat shows a very quick rewetting from dry
                conditions. Coir fibre is applied as a component in substrate
                mixtures for various crops. Coir fibre in blended mixtures,
                provides an overall coarser structure that drains easily.
              </p>
            </div>
            <EcoliteCocopeat />
            <div className="text-left mt-5">
              <div style={{ flex: 1, minWidth: '300px' }} className="mr-3">
                <p className="mt-0 mb-3 text-700 text-xl">
                  <span className="green-c">Applications</span>
                </p>
                <ul className="application-ul">
                  <ApplicationLi icon={<ImLeaf />} text="Young plants & seedlings" />
                  <ApplicationLi icon={<BsTreeFill />} text="Forestry & cuttings" />
                  <ApplicationLi icon={<GiBirchTrees />} text="Ornamentals & potting plants" />
                  <ApplicationLi icon={<GiKiwiFruit />} text="Soft fruits" />
                  <ApplicationLi icon={<GiFruitTree />} text="Fruit trees" />
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cocopeat;
