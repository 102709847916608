import React from 'react';
import { FiCheckCircle } from 'react-icons/fi';

const EcoliteCocopeat = () => {
  return (
    <>
      <p className="mt-5 mb-3 text-700 text-xl">
        Why <span className="green-c">Ecolite</span> Cocopeat?
      </p>

      <div className="text-600 font-medium">
        <p className="mb-2"><FiCheckCircle color="#00B500" className="me-2" />
          Suitable for vegetable and flowers
        </p>
        <p className="mb-2"><FiCheckCircle color="#00B500" className="me-2" />
          Promotes faster germination
        </p>
        <p className="mb-2"><FiCheckCircle color="#00B500" className="me-2" />
          Well balanced water retention to prevent drying out
        </p>
        <p className="mb-2"><FiCheckCircle color="#00B500" className="me-2" />
          Excellent aeration (air to water ratio) and drainage for strong fast growth
        </p>
        <p className="mb-2"><FiCheckCircle color="#00B500" className="me-2" />
          Contains beneficial microbes, balance pH and nutrients
        </p>
      </div>

    </>
  );
};

export default EcoliteCocopeat;
